import { ApprenticeSignupDto } from '@/components/shared/Apprentice/ApprenticeSignup'
import { UserRole } from '@qureos/types'
import apiClient from '../base'
import { set } from 'lodash'

export const registerApprenticeUser = async (
  body: ApprenticeSignupDto,
  gReCaptchaToken: string
) => {
  const payloadBody = {
    firstName: body.firstName.trim(),
    lastName: body.lastName.trim(),
    email: body.email.trim(),
    password: body.password,
    role: UserRole.STUDENT,
    dateOfBirth: body.dateOfBirth,
    signedUpForJob: body.signedUpForJob,
    referrer: body.referrer,
    captcha: gReCaptchaToken
  }
  if (body?.invite) {
    set(payloadBody, 'invite', body?.invite)
  }
  const response = await apiClient.post('auth/register', payloadBody)
  return response
}

export const verifyApprentice = async (email: string, code: number) =>
  apiClient.post('/auth/verify', {
    email,
    verificationCode: code
  })

export const signInWithGoogle = async (
  code: string,
  role?: string,
  referrer?: string,
  signedUpForJob?: boolean
) => {
  const response = await apiClient.post('auth/google-signin', {
    code,
    role,
    referrer,
    signedUpForJob
  })
  return response
}

export const resendEmailVerification = async (email: string) => {
  const resp = await apiClient.post('auth/resend-verification', {
    email
  })
  return resp.data
}

export const updateUserPassword = async (
  oldPassword: string,
  password: string
) => {
  const response = await apiClient.post('auth/change-password', {
    oldPassword,
    password
  })
  return response
}

export const getUser = (email: string) => {
  return apiClient.get(`/auth/user/${email}`)
}
