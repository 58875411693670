import clsx from 'clsx'
import { FunctionComponent } from 'react'

interface Props {
  border?: string
  height?: string
  light?: boolean
  width?: string
  className?: string
}

export const Spinner: FunctionComponent<Props> = ({
  border,
  height,
  light,
  width,
  className
}) => (
  <>
    <div
      className={clsx(
        className,
        'spinner',
        'border-2',
        'rounded-full',
        height ? height : 'h-4',
        width ? width : 'w-4',
        'mx-2',
        border ? border : light ? 'border-white' : 'border-black'
      )}
    />
    <style jsx>{`
      .spinner {
        animation: spinner 1s linear infinite;
        border-top-color: transparent !important;
        margin: 0 auto;
      }
      @keyframes spinner {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }
    `}</style>
  </>
)
