import { isUndefined } from 'lodash'

export const getQueryString = params => {
  const queryParams = new URLSearchParams()

  if (params) {
    for (const [key, value] of Object.entries(params)) {
      if (!isUndefined(value) && value !== '') {
        if (Array.isArray(value)) {
          value.forEach(value =>
            queryParams.append(`${key}[]`, value.toString())
          )
        } else {
          queryParams.append(key, value.toString())
        }
      }
    }
  }
  return queryParams.toString()
}
