import last from 'lodash/last'
import sum from 'lodash/sum'
import moment from 'moment'
import { NextRouter } from 'next/router'
import validator from 'validator'

import {
  DropdownItem,
  FileProps,
  MessageTypes,
  Progress,
  SignUpErrorResult,
  SignUpErrors,
  SignUpFields
} from '@qureos/types'
import {
  Compliment,
  LanguageProficiency,
  SkillRank,
  User,
  UserChallenge,
  UserChallengeStatus,
  UserSkill
} from 'src/types/shared/graphql'

interface ComplimentsRestructured {
  count: number
  icon: string
  id: string
  name: string
}

import { MoneyConversionResult, MoneyConversionTypes } from '@qureos/types'

import parsePhoneNumberFromString from 'libphonenumber-js'
import { isEmpty } from 'lodash'

export const calculateRating = (
  data: number[],
  additional?: number
): string => {
  const result = data.length > 0 ? (sum(data) / data.length).toFixed(2) : '0'

  return additional ? parseInt(additional + result).toFixed(2) : result
}

export const arrayToFiles = (files: string[]): FileProps[] => {
  const data: FileProps[] =
    files.length > 0
      ? files.map(file => {
          return {
            isLink: false,
            name: file,
            uri: file
          }
        })
      : []

  return data
}

export const formatPrice = (price: number) => {
  return price
    .toLocaleString('en-US', {
      currency: 'USD',
      style: 'currency'
    })
    .slice(0, -3)
}

export const findIndex = (collection: string[], value: string) => {
  // something is off on lodash findIndex. it always returns -1

  let result = -1

  collection.map((item, index) => {
    if (item === value) {
      result = index
    }
  })

  return result
}

export const checkPassword = (
  password: string
): {
  msg: string
  passed: boolean
} => {
  const checker = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/

  let message = ''
  let passed = true

  if (!checker.test(password)) {
    message =
      'Password should at least contain 1 capitalized letter, and a number.'
    passed = false
  }

  if (password.length < 8) {
    message = 'Password should be minimum of 8 characters.'
    passed = false
  }

  return {
    msg: message,
    passed: passed
  }
}

export const formatError = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors && graphQLErrors.length > 0) {
    return graphQLErrors[0].message
  }

  if (networkError) {
    return networkError.message
  }

  return null
}

export const getSelectedValues = (items: DropdownItem[]) => {
  const data: string[] = []

  items.map(item => {
    data.push(item.value)
  })

  return data
}

export const getYearGraduated = (value: string): number => {
  return value ? parseInt(last(value.split('/'))) : 0
}

export const arrayToDropdown = (items: (string | number)[]): DropdownItem[] => {
  const data: DropdownItem[] = []

  items.map(value => {
    if (value) {
      data.push({
        label: value.toString(),
        value: value.toString()
      })
    }
  })

  return data
}

export const identifyType = (suffix: string): MessageTypes => {
  let type = MessageTypes.File

  switch (suffix) {
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
      type = MessageTypes.Photo

      break

    case 'mp4':
    case 'avi':
    case 'mov':
      type = MessageTypes.Video

      break
  }

  return type
}

export const languageLevel = (proficiency: string): number => {
  let level = 1

  switch (proficiency) {
    case LanguageProficiency.Limited:
      level = 2
      break

    case LanguageProficiency.Professional:
      level = 3
      break

    case LanguageProficiency.Native:
      level = 4
      break

    default:
      break
  }

  return level
}

export const limitText = (text: string, limit: number): string => {
  return text && text.length > limit ? `${text.substr(0, limit)}...` : text
}

export const mapCompliments = (
  data: Compliment[]
): ComplimentsRestructured[] => {
  const result: ComplimentsRestructured[] = []

  data.map(item => {
    const { icon, id, name } = item

    let found = undefined

    result.map((existing, index) => {
      if (existing.id === item.id) {
        found = index

        return
      }
    })

    if (found) {
      result[found].count += 1
    }

    if (found === undefined) {
      result.push({
        count: 1,
        icon,
        id,
        name
      })
    }
  })

  return result
}

export const mapSkills = (skills: UserSkill[]) => {
  const data = {}

  skills.map(skill => {
    data[skill.skill.id] = skill.level
  })

  return data
}

export const mapProgress = (
  challenges: UserChallenge[],
  reviews: UserChallenge[]
): Record<string, Progress> => {
  const data = {}

  challenges.map(challenge => {
    const skillId = challenge.skill.id
    let level = challenge.level
    let index = challenge.index

    if (![UserChallengeStatus.Failed].includes(challenge.status)) {
      if (data[skillId]) {
        data[skillId].challenge = data[skillId].challenge + 1
      }

      if (!data[skillId]) {
        data[skillId] = {
          challenge: 1,
          review: 0
        }
      }

      data[skillId].challengeId =
        [UserChallengeStatus.Pending, UserChallengeStatus.Active].includes(
          challenge.status
        ) && challenge.id
    }

    if (
      ![UserChallengeStatus.Active, UserChallengeStatus.Failed].includes(
        challenge.status
      )
    ) {
      if (challenge.index < 3) {
        index = challenge.index + 1
      }

      if (challenge.index === 3 && challenge.level !== SkillRank.Skilled) {
        index = challenge.index = 1

        if (challenge.level === SkillRank.Beginner) {
          level = SkillRank.Proficient
        }

        if (challenge.level === SkillRank.Proficient) {
          level = SkillRank.Skilled
        }
      }

      data[skillId].index = index
      data[skillId].level = level
    }
  })

  reviews.map(review => {
    const skillId = review.skill.id

    if (data[skillId]) {
      data[skillId].review = data[skillId].review + 1
    }

    if (!data[skillId]) {
      data[skillId] = {
        challenge: 0,
        review: 1
      }
    }
  })

  return data
}

export const projectHelper = {
  isParticipant(
    userId: string,
    participants: User[],
    mentors: User[]
  ): boolean {
    let result = false

    participants.map(row => {
      if (row.id === userId) {
        result = true

        return
      }
    })

    if (!result && mentors) {
      mentors.map(mentor => {
        if (mentor.id === userId) {
          result = true

          return
        }
      })
    }

    return result
  }
}

/**
 * @deprecated
 * use segmentv2 instead
 */
export const segment = (index: number): string => {
  let value = ''

  if (process.browser) {
    const path = window.location.href.replace(process.env.APP_URL, '')
    value = path.split(/[?#]/)[index - 1]
  }

  return value
}

export const segmentV2 = (router: NextRouter, index: number): string => {
  const path = router.asPath.split('/')
  return path[index - 1]
}

export const translateAge = (date: string): string => {
  if (!date) {
    return ''
  }

  const split = date.split(' ')
  const suffix = split[1]
  let prefix = split[0] === 'a' ? '1' : split[0]

  let label = '< 1 min'

  if (prefix === 'an') {
    prefix = '1'
  }

  switch (suffix) {
    case 'minutes':
      label = `${prefix}m`
      break
    case 'hours':
    case 'hour':
      label = `${prefix}hr`
      break
    case 'days':
    case 'day':
      label = `${prefix}d`
      break
    case 'weeks':
    case 'week':
      label = `${prefix}w`
      break
    case 'months':
    case 'month':
      label = `${prefix}m`
      break
    case 'years':
    case 'year':
      label = `${prefix}yr`
      break
    default:
      label = '< 1 min'
      break
  }

  return label
}

export const validation = {
  signup: (
    input: SignUpFields,
    step: number,
    googleId: string,
    linkedinId: string
  ): SignUpErrorResult => {
    let passed = true
    const messages: SignUpErrors = {
      categories: '',
      code: '',
      confirmPassword: '',
      email: '',
      name: '',
      password: ''
    }

    const { name, email, password, confirmPassword, code } = input

    if (step === 0) {
      if ((googleId === '' || linkedinId === '') && validator.isEmpty(name)) {
        messages.name = "What's your name?"

        passed = false
      }

      if ((googleId === '' || linkedinId === '') && !validator.isEmail(email)) {
        messages.email = 'Please use a valid email.'

        passed = false
      }

      if (
        (googleId === '' || linkedinId === '') &&
        validator.isEmpty(password)
      ) {
        messages.password = 'Please set a password.'

        passed = false
      }

      if (!validator.isEmpty(password) && confirmPassword !== password) {
        messages.confirmPassword =
          'Password and Confirm Password must be the same.'

        passed = false
      }
    }

    if (step === 1) {
      if (input.categories.length <= 0) {
        messages.categories =
          'Please select at least one topics you were interested in.'

        passed = false
      }
    }

    if (step === 2) {
      if (validator.isEmpty(code) || !validator.isNumeric(code)) {
        messages.code = 'Verification code is mandatory and must be a number.'

        passed = false
      }
    }

    return {
      errors: messages,
      passed: passed
    }
  }
}

export const httpsProtocol = () => {
  if (window) {
    const {
      location: { protocol, hostname }
    } = window

    if (hostname !== 'localhost' && protocol === 'http:') {
      window.location.href = `https://${hostname}`
    }
  }
}

export const dropdownFilter = (
  input: string,
  option: string | React.ReactNode
): boolean => {
  return String(option).toLowerCase().indexOf(input.toLowerCase()) >= 0
}

export const isFunction = functionToCheck => {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
  )
}

export const replaceHtmlEntites = (function () {
  const translateRe = /&(nbsp|amp|quot|lt|gt);/g,
    translate = {
      amp: '&',
      gt: '>',
      lt: '<',
      nbsp: String.fromCharCode(160),
      quot: '"'
    },
    translator = function ($0, $1) {
      return translate[$1]
    }

  return function (s) {
    return s.replace(translateRe, translator)
  }
})()

export const stripHtmlEntities = str => {
  return replaceHtmlEntites(str).replace(/<\/?[^>]+(>|$)/g, '')
}

export const qureosMoney = (amount: number, type: string) => {
  const dollarConversion = 3.67
  const dirhamConversion = 0.3
  // const amount =
  // cont
  //   input.currency == 'AED'
  //     ? input.xp * 0.3
  //     : (input.xp * 0.3) / dollarConversion

  const { xp, dollar, dirham } = MoneyConversionTypes

  const data: MoneyConversionResult = {}

  switch (type) {
    case xp:
      data.dirham = Math.round(amount * dirhamConversion)
      data.dollar = Math.round((amount * dirhamConversion) / dollarConversion)
      break
    case dollar:
      // data.xp = dollarConversion * amount
      break
    case dirham:
      break
  }

  return data
}

// custom helper for aeic students only
interface PageBlockerProps {
  user: User
  allChallenges: UserChallenge[]
}

export const isPageBlocked = (input: PageBlockerProps): boolean => {
  let result = false

  const { allChallenges, user } = input

  const now = moment().unix()

  const challenges = allChallenges.filter(e => {
    let result = false

    const {
      challenge: { signupCode, proficient },
      level
    } = e

    if (
      signupCode.includes('aeic') &&
      level === SkillRank.Proficient &&
      proficient &&
      now > moment(proficient.duration?.endDate.date).unix()
    ) {
      result = true
    }

    return result
  })

  if (user.signupCode === 'aeic' && challenges.length <= 0) {
    result = true
  }

  return result
}

export const permutator = inputArr => {
  const result = []

  const permute = (arr, m = []) => {
    if (arr.length === 0) {
      result.push(m)
    } else {
      for (let i = 0; i < arr.length; i++) {
        const curr = arr.slice()
        const next = curr.splice(i, 1)
        permute(curr.slice(), m.concat(next))
      }
    }
  }

  permute(inputArr)

  return result
}

export const parsePhoneNumber = phoneNumberString => {
  try {
    const phoneNumber = parsePhoneNumberFromString(phoneNumberString)
    const phoneNum = phoneNumber.nationalNumber
    const country = phoneNumber.country
    const preFix = phoneNumber.countryCallingCode
    return { phoneNum, country, preFix }
  } catch (e) {
    return { phoneNum: '', country: '', preFix: '' }
  }
}

export const formatNumber = (number: string) => {
  if (isEmpty(number)) return null

  if (number && number.includes('+')) {
    return number
  } else {
    return '+' + number
  }
}
