import { ButtonIcon, Spinner } from '@/components/shared/basic'
import { googleLogo } from '@qureos/assets'
import { Dictionary } from 'lodash'
import { FC } from 'react'
import { ShouldRender } from '../ShouldRender'
import { SocialLoginProvider } from './SocialLoginProvider'

export interface GoogleDto {
  email?: string
  name?: string
  tokenId?: string
  code?: string
}

type Props = {
  title: string
  login: () => void
  loading: boolean
  linkedInCallback?: (token: string) => void
  allowCode?: boolean
  errors?: Dictionary<JSX.Element | string>
}

export const SocialLoginButtons: FC<Props> = ({
  title,
  errors,
  login,
  loading
}) => {
  return (
    <div data-testid="google-login-container" className="flex flex-col">
      <div
        data-testid="google-login-button"
        className="flex justify-between gap-4">
        <ButtonIcon onClick={login}>
          <div className="flex justify-center gap-2 items-center">
            <ShouldRender check={loading}>
              <Spinner />
            </ShouldRender>
            <img src={googleLogo} alt="google" className="h-5 w-5" />
            <span className="text-black text-opacity-70 font-medium">
              {title} Google
            </span>
          </div>
        </ButtonIcon>
        {/* <ButtonIcon onClick={linkedInLogin}>
          <img src={corporateLinkedInIcon} alt="linkedin" />
        </ButtonIcon> */}
      </div>
      <ShouldRender check={errors && errors['social']}>
        <div className="inline-flex items-center text-red-500">
          {errors && errors['social']}
        </div>
      </ShouldRender>
    </div>
  )
}

export default SocialLoginProvider
