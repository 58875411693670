import { Dictionary } from 'lodash'
import { FC, useState } from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import { formatError, notify } from '@qureos/lib'
import { SocialLoginButtons } from '.'

export interface GoogleDto {
  email?: string
  name?: string
  tokenId?: string
  code?: string
}

type Props = {
  title: string
  googleCallback: (googleDto: GoogleDto) => void
  linkedInCallback?: (token: string) => void
  allowCode?: boolean
  errors?: Dictionary<JSX.Element | string>
}

export const SocialLoginProvider: FC<Props> = ({
  title,
  googleCallback,
  allowCode = true,
  errors
}) => {
  const [googleLoading, setGoogleLoading] = useState<boolean>(false)

  const login = useGoogleLogin({
    onSuccess: async result => {
      await handleGoogleLogin(result)
      setGoogleLoading(true)
    },
    onError: () => {
      notify({
        message: 'Unable to connect with google, please try again',
        type: 'error'
      })
      setGoogleLoading(false)
    },
    flow: 'auth-code'
  })

  const handleGoogleLogin = async googleData => {
    try {
      const dto: GoogleDto = allowCode
        ? {
            code: googleData.code
          }
        : {
            email: googleData?.hd,
            name: googleData?.consent,
            tokenId: googleData?.code
          }
      googleCallback(dto)
    } catch (error) {
      const message = formatError(error)
      notify({ message: message, type: 'error' })
    } finally {
      setGoogleLoading(false)
    }
  }

  // const { linkedInLogin } = useLinkedIn({
  //   clientId: process.env.LINKEDIN_CLIENT_ID,
  //   redirectUri: process.env.LINKEDIN_REDIRECT_URI,
  //   onSuccess: async code => {
  //     console.log(code)
  //   },
  //   scope: 'r_emailaddress r_liteprofile',
  //   onError: error => {
  //     console.log(error)
  //   }
  // })

  return (
    <SocialLoginButtons
      login={login}
      loading={googleLoading}
      title={title}
      errors={errors}
    />
  )
}
