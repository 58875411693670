import clsx from 'clsx'
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js'

import IntlTelInputBase from 'react-intl-tel-input'

export interface IntlTelData {
  phone: string
  phoneCountryCode: string
  phonePrefix: string
  isPhoneValid: boolean
  countryName?: string
}

interface Props {
  phone: string
  phoneCountryCode: string
  onChange: (data: IntlTelData) => void
  focusBorderColor?: string
  disabled?: boolean
  fieldName?: string
}

export const IntlTelInput: React.FC<Props> = ({
  phone,
  phoneCountryCode,
  onChange,
  focusBorderColor = 'focus:border-blue-600',
  fieldName = '',
  disabled
}) => {
  const inputClasses = [
    'font-normal',
    'text-gray-700',
    'placeholder-gray-500',
    'rounded-md',
    'border',
    'border-solid',
    'border-gray-300',
    'py-2',
    'leading-tight',
    'px-3.5',
    'outline-0',
    'focus-visible:ring-0',
    focusBorderColor
  ]

  const checkNumberValidity = (number: string, iso2: string) => {
    return (
      iso2 &&
      isValidPhoneNumber(number, iso2.toUpperCase() as CountryCode) &&
      number[0] !== '0'
    )
  }

  return (
    <div className="intl-tel-wrapper">
      <IntlTelInputBase
        disabled={disabled}
        inputClassName={clsx(
          ...inputClasses,
          'pl-[130px]',
          disabled ? 'text-gray-400 cursor-not-allowed' : 'text-normal'
        )}
        fieldName={fieldName}
        preferredCountries={['ae', 'sa', 'pk', 'in']}
        value={phone ?? ''}
        separateDialCode={true}
        defaultCountry={phoneCountryCode?.toLowerCase() || 'uae'}
        formatOnInit={false}
        format={false}
        onSelectFlag={(number, { iso2, dialCode, name }, _, isValid) => {
          isValid ?? checkNumberValidity(number, iso2)
          onChange({
            phone: number,
            phoneCountryCode: iso2,
            phonePrefix: dialCode,
            isPhoneValid: isValid,
            countryName: name
          })
        }}
        onPhoneNumberChange={(isValid, value, { dialCode, iso2, name }) => {
          isValid ?? checkNumberValidity(value, iso2)
          onChange({
            phone: value,
            phoneCountryCode: iso2,
            phonePrefix: dialCode,
            isPhoneValid: isValid,
            countryName: name
          })
        }}
      />
    </div>
  )
}
