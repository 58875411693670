import anchorme from 'anchorme'
import { keys } from 'lodash'
import { FunctionComponent, useEffect, useRef } from 'react'
import ReactHtmlParser from 'react-html-parser'

interface Props {
  content: string
  className?: string
}

export const Parser: FunctionComponent<Props> = ({ content, className }) => {
  const div = useRef<HTMLDivElement>()

  useEffect(() => {
    if (div) {
      const elements = div.current.getElementsByTagName('a')

      keys(elements).map(key => {
        const element: HTMLAnchorElement = elements[key]

        element.setAttribute('target', '_blank')
        element.setAttribute('rel', 'noopener noreferrer')
        element.style.wordBreak = 'break-all'
      })
    }
  })

  return (
    <div ref={div} className={`w-full parsed-content ${className}`}>
      {ReactHtmlParser(
        anchorme({
          input: content,
          options: {
            attributes: {
              target: '_blank'
            }
          }
        })
      )}
    </div>
  )
}
