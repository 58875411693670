declare global {
  interface Window {
    hj: (
      event: HotjarEvents,
      userId?: string,
      attributes?: HotjarCustomAttributes
    ) => void
  }
}

enum HotjarEvents {
  identify = 'identify',
  reset = 'reset'
}

interface HotjarCustomAttributes {
  email: string
}

export const identifyUser = (
  userId: string,
  attributes: HotjarCustomAttributes
) => {
  if (typeof window !== 'undefined' && 'hj' in window) {
    window.hj(HotjarEvents.identify, userId, {
      // Add your own custom attributes here.
      ...attributes
    })
  }
}

export const logoutSegment = () => {
  if (typeof window !== 'undefined' && 'hj' in window) {
    window.hj(HotjarEvents.reset)

    console.log('SEGMENT EVENTS NOW RESET')
  }
}
