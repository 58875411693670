export * from './admin'
export * from './apprentice'
export * from './jobs'
export * from './corporate'
export * from './project'
export * from './company'
export * from './webinar'
export * from './onboarding'
export * from './cohort'
export * from './shared'
export * from './mentor'
export * from './segment'
export * from './unleash'
export * from './workspace'
import { Project, SkillRank, User } from './shared/graphql'
export enum ApiVersion {
  V1 = 'v1',
  V2 = 'v2'
}

export enum SortingDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export interface BoostWays {
  profile: boolean
  skills: boolean
  challenges: boolean
}

export interface CountryProps {
  name: string
  countryCode: string
  dialCode: string
}

export interface CountdownProps {
  days: number
  hours: number
  minutes: number
}

export interface CropSelection {
  height: number
  width: number
  x: number
  y: number
}

export interface DropdownItem {
  label: string
  value: string
}

export interface FileProps {
  name: string
  uri: string
  isLink?: boolean
}

export interface HeaderLinks {
  label: string
  link: string
  isActive?: boolean
}

export interface InitialMessageProps {
  author: string
  text: string
  date: string
}

export interface Progress {
  challenge: number
  challengeId: string | undefined
  index: number
  level: SkillRank
  review: number
}

export interface GoogleBirthday {
  date: {
    day: number
    month: number
    year: number
  }
}

export interface RateData {
  feedback?: string
  compliments: string[]
  rating: number
  reported: boolean
  reason?: string
}

export interface RegisterErrors {
  email?: string
  mobile?: string
  name?: string
  password?: string
}

export enum GlobalMessages {
  EVENT_CANCELLED = 'This feature is now locked due to event cancellation'
}

export interface SignUpFields {
  code?: string
  confirmPassword?: string
  email?: string
  name?: string
  password?: string
  categories?: Array<string>
}

export interface SchoolValidationErrorProps {
  schoolName?: string
  schoolEmail?: string
}

export interface SeoData {
  user?: User
  project?: Project
}
export enum ThemesType {
  LEARNING_PATH = 'learning-paths',
  MASTERCLASSES = 'masterclasses',
  MENTORSHIP = 'mentorship',
  MENTORSHIP_TOPICS = 'mentorship-topics',
  MENTORS = 'mentors',
  JOBS = 'jobs'
}

// todo: deprecate usage soon.
export interface SignUpErrors {
  code?: string
  categories?: string
  company?: string
  confirmPassword?: string
  degree?: string
  dateGraduated?: string
  email?: string
  endDate?: string
  name?: string
  nationality?: string
  password?: string
  secondaryEmail?: string
  school?: string
  startDate?: string
  website?: string
}

export interface TimeData {
  elapse: number
  range: number
  progress: number
  remaining: number
}

export interface NotificationMessage {
  type: 'error' | 'success'
  message: string
}

export interface PhoneProps {
  countryCode: string
  number: string
  prefix: string
}

export interface SignUpErrorResult {
  errors: SignUpErrors
  passed: boolean
}

export interface TwilioMessage {
  id: string
  author: string
  body: string
  timestamp: string
}

export interface UploadResult {
  fileName: string
  type: MessageTypes
  uri: string
  size?: number
  uploadedAt?: Date
}

// todo: deprecate usage of this
export interface ErrorMessage {
  type: 'error' | 'success'
  message: string
}

export interface Theme {
  isDeleted?: boolean
  label: string
  value: string
  _id: string
}

export enum MessageTypes {
  Audio = 'audio',
  File = 'file',
  Loading = 'loading',
  Photo = 'photo',
  String = 'string',
  Video = 'video'
}

export enum TermContentType {
  Conduct = 'conduct',
  Mentor = 'mentor',
  Privacy = 'privacy',
  Website = 'website',
  Project = 'project',
  NDA = 'nda'
}

// types
export const ForumEmojis = {
  LAUGH: '😁',
  LIKE: '👍',
  WOW: '😮'
}

export enum MentorOnboardingStatus {
  NEW = 'New',
  IN_REVIEW = 'To be adjusted',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',

  // Removed statuses, only keeping them so statusHistory can be viewed
  IN_PROGRESS = 'Pending Profile Completion',
  PENDING_REVIEW = 'Pending Review',
  VERIFIED = 'Verified',
  DROPPED = 'Dropped'
}

export enum MentorOnboardingStatusDto {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  IN_REVIEW = 'IN_REVIEW',
  REJECTED = 'REJECTED'
}

export const DefaultTimezone = 'Asia/Dubai'

export const ChallengeLevelReward = 5
export const DATE_FORMAT = 'YYYY-MM-DD'
export const EffortOptions = {
  '12': '8-12 hours',
  '16': '12-16 hours',
  '4': '1-4 hours',
  '8': '4-8 hours'
}
export const MentorSessionReward = 25
export const PeerSessionReward = 10

export const MoneyConversionTypes = {
  dirham: 'dirham',
  dollar: 'dollar',
  xp: 'xp'
}

export interface MoneyConversionResult {
  dirham?: number
  dollar?: number
  xp?: number
}

export const InternalMentors = {
  '5e429261db3a2a0017b9d5b0': {
    evaluations: 50,
    ratings: 4.41,
    reputation: 76.8,
    sessions: 52
  },
  '5e429261db3a2a0017b9d5b1': {
    evaluations: 67,
    ratings: 4.23,
    reputation: 86.8,
    sessions: 67
  },
  '5e429261db3a2a0017b9d5b2': {
    evaluations: 61,
    ratings: 4.65,
    reputation: 77.25,
    sessions: 57
  },
  '5e429261db3a2a0017b9d5b3': {
    evaluations: 65,
    ratings: 4.12,
    reputation: 88.2,
    sessions: 60
  },
  '5e429261db3a2a0017b9d5b4': {
    evaluations: 59,
    ratings: 4.71,
    reputation: 85.12,
    sessions: 55
  },
  '5e429261db3a2a0017b9d5b5': {
    evaluations: 50,
    ratings: 4.42,
    reputation: 84.21,
    sessions: 52
  },
  '5e429261db3a2a0017b9d5b6': {
    evaluations: 60,
    ratings: 4,
    reputation: 84.21,
    sessions: 52
  },
  '5e429261db3a2a0017b9d5b7': {
    evaluations: 50,
    ratings: 4.65,
    reputation: 89.19,
    sessions: 53
  },
  '5e429261db3a2a0017b9d5b8': {
    evaluations: 55,
    ratings: 4.33,
    reputation: 86.11,
    sessions: 51
  },
  '5e429261db3a2a0017b9d5b9': {
    evaluations: 58,
    ratings: 4.6,
    reputation: 84.32,
    sessions: 51
  },
  '5e429261db3a2a0017b9d5ba': {
    evaluations: 54,
    ratings: 4.22,
    reputation: 84.19,
    sessions: 51
  },
  '5e429261db3a2a0017b9d5bb': {
    evaluations: 51,
    ratings: 4.21,
    reputation: 85.23,
    sessions: 51
  },
  '5e429261db3a2a0017b9d5bc': {
    evaluations: 50,
    ratings: 4.4,
    reputation: 79.87,
    sessions: 52
  },
  '5e429261db3a2a0017b9d5bd': {
    evaluations: 57,
    ratings: 4.78,
    reputation: 88.19,
    sessions: 56
  },
  '5e429261db3a2a0017b9d5be': {
    evaluations: 65,
    ratings: 4.79,
    reputation: 91.64,
    sessions: 66
  }
}

// test deployment

export enum OfferingEnum {
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM'
}

export type ValueOf<T> = T[keyof T]
export interface NewPricing {
  value: number
  currency: string
}

export enum NewDiscountType {
  PERCENTAGE = 'percentage',
  VALUE = 'value'
}
export interface NewDiscount {
  value: number
  type: NewDiscountType
}

export type WithID<T> = T & {
  _id: string
}

export interface ISubscriptionBenefits {
  _id: string
  checked: boolean
  label: string
  parentCategory?: string
}
