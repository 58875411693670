export class ToolsDto {
  _id: string

  name: string

  status?: string
}

export class ExternalCertificateDto {
  name: string

  issuingCompany: string

  companyUrl: string

  credentialUrl: string

  issueDate: Date
}

export class EducationDto {
  major: string

  school: string

  graduationYear: number
}

export class WorkExperienceDto {
  role: string

  company: string

  website: string
}

export class UpdateProfileDto {
  photo: string

  firstName: string

  lastName: string

  email: string

  languages: string[]

  phone: string

  location: string

  about: string

  experience: WorkExperienceDto[]

  education: EducationDto[]

  certifications: ExternalCertificateDto[]

  tools: ToolsDto[]
}

export enum UserRole {
  ADMIN = 'ADMIN',
  MENTOR = 'MENTOR',
  PROFESSIONAL = 'PROFESSIONAL',
  STUDENT = 'STUDENT',
  ENTERPRISE = 'ENTERPRISE',
  PUBLIC = 'PUBLIC'
}

export interface userSetting {
  _id: string
  isFeedbackRequestDisabled: boolean
  createdBy: { _id: string; name: string }
}
