import { FC, ReactNode } from 'react'

import style from './ButtonIcon.module.scss'

interface Props {
  children?: ReactNode
  onClick?: () => void
}

export const ButtonIcon: FC<Props> = ({ children, onClick }) => (
  <button className={style.buttonIcon} type="button" onClick={onClick}>
    {children}
  </button>
)
