import apiClient from 'src/services/base'
import { getQueryString } from '@/utils/static-helpers/query-params'
import {
  JobApplicantDetails,
  JobApplication,
  JobApplicationResponse,
  JobApplicationStatus,
  JobsMeta
} from 'src/types'
import {
  AllPublicJobsResponse,
  PublicJobResponse
} from 'src/types/shared/public-jobs'
import { isNil } from 'lodash'
import { JDGeneratorParams } from 'src/hooks/data/useJobsServices'
import { JobDetailCounts, JobStats } from 'src/types/jobs/stats'
import * as Sentry from '@sentry/browser'
import axios from 'axios'
import { IScreeningQuestionAnswerV2 } from './jobsv2'
export type ListJobsRequestParams = {
  companyIds?: string[]
  contractTypes?: string[]
  ignoreAppliedJobs: boolean
  includePublicJobs?: boolean
  limit?: number
  location?: string
  offset?: number
  statuses?: string[]
  themeIds?: string[]
  title?: string
  includeCompanyDetails?: boolean | string
  includeRecommendations?: boolean | string
  closedAtlte?: string
  closedAtgte?: string
  closedAteql?: string
  includeConvertedJobs?: boolean
}

export const getAllJobs = async (params: ListJobsRequestParams) => {
  const {
    ignoreAppliedJobs,
    includePublicJobs,
    includeConvertedJobs,
    ...rest
  } = params
  const queryParams = new URLSearchParams()
  if (ignoreAppliedJobs) {
    queryParams.append('ignoreAppliedJobs', 'true')
  }
  if (includePublicJobs) {
    queryParams.append('includePublicJobs', 'true')
  }
  if (includeConvertedJobs) {
    queryParams.append('includeConvertedJobs', 'true')
  }
  if (rest) {
    for (const [key, value] of Object.entries(rest)) {
      if (
        key === 'companyIds' ||
        key === 'contractTypes' ||
        key === 'statuses' ||
        key === 'themeIds'
      ) {
        params[key].forEach(value => queryParams.append(key + '[]', value))
      } else if (key.includes('closedAt')) {
        const splittedKey = key.split('closedAt')
        queryParams.append(`closedAt[${splittedKey[1]}]`, value.toString())
      } else if (!isNil(value)) {
        queryParams.append(key, value.toString())
      }
    }
  }

  return apiClient.get(`/jobs?${queryParams.toString()}`).then(res => res.data)
}

export const getJobsMeta = async (params?: {
  includeThemeLocationPair?: boolean
  includeActiveJobsSlugs?: boolean
  includeCompanies?: boolean
  bearerToken?: string
}): Promise<JobsMeta> => {
  const queryParams = new URLSearchParams()
  if (params?.includeThemeLocationPair) {
    queryParams.append('includeThemeLocationPair', 'true')
  }
  if (params?.includeActiveJobsSlugs) {
    queryParams.append('includeActiveJobsSlugs', 'true')
  }
  if (params?.includeCompanies) {
    queryParams.append('includeCompanies', 'true')
  }

  let apiClientOverride = apiClient
  let url = `jobs/meta/?${queryParams.toString()}`

  if (params?.bearerToken) {
    apiClientOverride = axios
    url = `${process.env.API_URL}/${url}`
  }

  return apiClientOverride
    .get(url, {
      timeout: 600000,
      headers: {
        Connection: 'keep-alive',
        Authorization: `Bearer ${params?.bearerToken}`
      }
    })
    .then(res => {
      return res.data
    })
    .catch(error => {
      Sentry.captureException(error, {
        extra: {
          message: '[getJobsMeta] - error fetching jobs meta'
        }
      })
      return {}
    })
}

export const getFilteredDataFYP = async () =>
  apiClient.get(`/for-you/filters`).then(res => res.data)

export const getJobsByCorporateUser = async (query = '', isApproved = false) =>
  apiClient
    .get(`/jobs/corporate?q=${query}${isApproved ? '&isApproved=true' : ''}`)
    .then(res => res.data)

export const getAppliedJobsByApprentice = async () =>
  apiClient.get(`/jobs/apprentice`).then(res => res.data)

export const getJobTabsCount = async (id: string) =>
  apiClient.get<JobDetailCounts>(`jobs/${id}/limit`).then(res => res.data)

/**
 * @deprecated Use getJob from jobsv2.ts instead
 */
export const getJobDetail = async (
  id: string,
  includePublicJobs = false,
  includeRejectionCount = false
) =>
  apiClient
    .get(
      `/jobs/${encodeURI(id)}?includeApprentices=true&${
        includePublicJobs ? 'includePublicJobs=true' : ''
      }&${includeRejectionCount ? 'includeRejectionCount=true' : ''}`
    )
    .then(res => res.data)

export const getJobDetailsWithApplication = async (
  id: string,
  includePublicJobs = false
) =>
  apiClient
    .get(
      `/jobs/application/${id}?${
        includePublicJobs ? 'includePublicJobs=true' : ''
      }`
    )
    .then(res => res.data)

interface JobFilesDto {
  url: string
  name: string
}

interface JobApplicationDto {
  description: string
  files: JobFilesDto[]
  link: string
}

export const applyJob = async (id: string, dto: JobApplicationDto) =>
  apiClient.post(`/jobs/application/${id}`, dto).then(res => res.data)

interface StartApplyParams {
  id: string
  cv: string
  screeningQuestionsAnswers: any // Update this type based on your actual type
  parseCV?: boolean
  screeningQuestionAnswersV2?: IScreeningQuestionAnswerV2[]
}

export function startApply(params: StartApplyParams): Promise<any>
export function startApply(
  id: string,
  cv: string,
  screeningQuestionsAnswers: any,
  parseCV?: boolean
): Promise<any>
export function startApply(
  idOrParams: string | StartApplyParams,
  cv?: string,
  screeningQuestionsAnswers?: any,
  parseCV?: boolean,
  screeningQuestionAnswersV2?: IScreeningQuestionAnswerV2[]
) {
  if (typeof idOrParams === 'string') {
    return apiClient.post(`/jobs/${idOrParams}/apply`, {
      cv,
      screeningQuestionsAnswers,
      parseCV,
      screeningQuestionAnswersV2
    })
  }

  return apiClient.post(`/jobs/${idOrParams.id}/apply`, {
    cv: idOrParams.cv,
    screeningQuestionsAnswers: idOrParams.screeningQuestionsAnswers,
    parseCV: idOrParams.parseCV,
    screeningQuestionAnswersV2: idOrParams.screeningQuestionAnswersV2
  })
}

export const triggerSubmissionEmail = async (jobId: string) => {
  return apiClient
    .post(`/jobs/public/${jobId}/trigger-submission-email`)
    .then(res => res.data)
}
export const createJob = (data: any): Promise<any> => {
  return apiClient
    .post(
      `jobs`,
      {
        ...data
      },
      {
        timeout: 600000,
        headers: {
          Connection: 'keep-alive'
        }
      }
    )
    .then(res => res.data)
}

export const createJobV2 = (data: any): Promise<any> => {
  return apiClient
    .post(
      `jobs/v2`,
      {
        ...data
      },
      {
        timeout: 600000,
        headers: {
          Connection: 'keep-alive'
        }
      }
    )
    .then(res => res.data)
}

export const createJobV2Clone = (id: string): Promise<any> => {
  return apiClient
    .post(`jobs/v2/clone`, {
      instantJobId: id
    })
    .then(res => res.data)
}

export const editJob = async (id: string, data: any) => {
  return apiClient
    .patch(`jobs/${id}?includeAppentices=true`, {
      ...data
    })
    .then(res => res.data)
}

export const editJobV2 = async (id: string, data: any) => {
  return apiClient
    .patch(`jobs/${id}/v2`, {
      ...data
    })
    .then(res => res.data)
}

export const deleteJob = async (id: string) =>
  apiClient.delete<PublicJobResponse>(`/jobs/${id}`).then(res => res.data)

/*
  job applications
*/

export const getAllJobApplications = async (offset = 0, limit = 10, params) => {
  const queryString = getQueryString({ ...params, offset, limit })
  const response = await apiClient.get<JobApplicationResponse>(
    `/jobs/application/v2?${queryString}`
  )
  return response.data
}

export const rateJobApplication = async (
  id: string,
  cvRating: number,
  submissionRating: number,
  categoryIds?: string[]
) => {
  const response = await apiClient.patch(`/jobs/application/${id}`, {
    cvRating,
    submissionRating,
    categoryIds
  })
  return response.data
}

export const favoriteJobApplication = async (id: string, favorite: boolean) => {
  return apiClient
    .patch(`/jobs/application/${id}`, {
      favorite
    })
    .then(response => response.data)
}

export const updateJobApplicationStatus = async (
  id: string,
  status: string,
  reason?: string,
  sendNotification?: boolean,
  matchRating?: number,
  matchReason?: string
) => {
  return apiClient
    .put(
      `/jobs/application/${id}/status`,
      {
        status,
        reason,
        sendNotification,
        matchRating,
        matchReason
      },
      { timeout: 120_000 }
    )
    .then(response => response.data)
}

export const getJobApplicationsByJob = async (jobId, params?) => {
  const queryString = getQueryString({ ...params })
  return apiClient
    .get(`/jobs/${jobId}/application?${queryString}`)
    .then(response => response.data)
}

export const getJobApplicantDetails = async (applicationId: string) =>
  apiClient
    .get<JobApplicantDetails>(`/jobs/application/${applicationId}/applicant/`)
    .then(resp => resp.data)

export const getApplicantByRecommendationId = async (
  jobId: string,
  recommendationId: string
) =>
  apiClient
    .get<JobApplication>(`/jobs/application/applicant/`, {
      params: { jobId, recommendationId }
    })
    .then(resp => resp.data)

export const getJobProjectIdMap = async (id: string) =>
  apiClient.get(`/jobs/project-mapping/${id}`).then(res => res.data)

/* public jobs */

export const getAllPublicJobs = async (offset = 0, limit = 10, params) => {
  const queryString = getQueryString({ ...params, offset, limit })
  const response = await apiClient.get<AllPublicJobsResponse>(
    `/jobs/public?${queryString}`
  )
  return response.data
}

export const bulkCreatePublicJobs = async (
  jobsToAdd: Partial<PublicJobResponse>[]
) => apiClient.post(`/jobs/public/bulk`, jobsToAdd).then(resp => resp.data)

export const updatePublicJob = async (
  id: string,
  payload: Partial<PublicJobResponse>
) =>
  apiClient
    .patch<PublicJobResponse>(`/jobs/public/${id}`, payload)
    .then(res => res.data)

export const deletePublicJob = async (id: string) =>
  apiClient
    .delete<PublicJobResponse>(`/jobs/public/${id}`)
    .then(res => res.data)

export const getJobDescription = async (
  params: Omit<JDGeneratorParams, 'addLineBreaks'>
): Promise<string> => {
  return await apiClient
    .post('/jobs/generate-description', params)
    .then(resp => resp.data)
}

export const getJobStats = async () =>
  apiClient.get<JobStats>('/jobs/stats').then(resp => resp.data)

export const markCandidatesAsSeen = async (params: {
  jobId: string
  statuses: JobApplicationStatus[]
}) => {
  return apiClient
    .patch('/jobs/application', { ...params, isSeen: true, hasUpdates: false })
    .then(resp => resp.data)
}
