import { CorporateSignupStep1 } from '@/components/corporate'
import { Input, Parser } from '@/components/shared/basic'
import { Button } from '@/components/shared/basic/Buttons'
import QureosCarousel from '@/components/shared/basic/Carousel/QureosCarousel'
import { GoogleDto, Photo } from '@/components/shared/misc'
import { PasswordStrength } from '@/components/shared/misc/PasswordStrength'
import SocialLoginButtons from '@/components/shared/misc/SocialLoginButtons'
import { login, loginRedirect, notify } from '@/utils/lib'
import { apprenticeCarouselDataBlue } from '@/utils/static-helpers/apprenticeCarouselData'
import {
  IPasswordStrength,
  validatePassword
} from '@/utils/static-helpers/validations'
import { qureosRevisedLogo } from '@qureos/assets'
import { SegmentEventType, UserRole } from '@qureos/types'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import {
  registerApprenticeUser,
  signInWithGoogle
} from 'src/services/apprentice/auth-apprentice'
import { AuthType } from 'src/types/shared/auth'

import AuthTabs from '@/components/auth-toggle'
import { identifyUser } from '@/utils/hotjar'
import { segmentIdentifyCallback, segmentTrackCallback } from '@/utils/segment'
import { Dictionary, set } from 'lodash'
import moment from 'moment'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useLogin } from 'src/hooks/useLogin'

export interface ApprenticeSignupDto {
  firstName: string
  lastName: string
  email: string
  password?: string
  dateOfBirth: Date
  signedUpForJob?: boolean
  referrer?: string
  invite?: string
}
interface Props {
  onAuthTypeChange: (type: AuthType) => void
}
const ApprenticeSignup: React.FC<Props> = ({ onAuthTypeChange }) => {
  const router = useRouter()
  const apprenticeInstantJobId = router?.query?.apprenticeInstantJobId
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>()
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [isGdprAccepted, setIsGdprAccepted] = useState<boolean>(false)
  const [isTermsAgreed, setIsTermsAgreed] = useState<boolean>(false)
  const [passwordStrength, setPasswordStrength] = useState<IPasswordStrength>(
    validatePassword(password)
  )
  const redirectUrl = loginRedirect('get') as string
  const [loading, setLoading] = useState<boolean>(false)
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)
  const [errors, setErrors] = useState<Dictionary<JSX.Element | string>>({})
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)
  const { redirectUser } = useLogin()

  const { executeRecaptcha } = useGoogleReCaptcha()

  const referrer: string = router?.query?.referrer as string
  const invite = router?.query?.invite as string

  // TODO: use when Formik is added
  // const signupSchema = yup.object().shape({
  //   name: yup.string().trim().required('Please provide Full Name'),
  //   email: yup.string().trim().required('Please provide Email'),
  //   password: yup
  //     .string()
  //     .min(8, 'Password should be 8 or characters long')
  //     .required('Please provide Password'),
  //   dateOfBirth: yup
  //     .date()
  //     .min(
  //       moment().subtract(18, 'years').toDate(),
  //       'Sorry, but we cannot complete your sign up, your age does not comply with our age-restriction policy, please check our <a href="https://app.qureos.com/privacy-policy">privacy policy</a>'
  //     )
  //     .max(new Date(), 'Date of Birth cannot be in the future')
  //     .required('Please provide Date of Birth')
  // })

  useEffect(() => {
    // To update error message on runtime
    if (hasSubmitted) validateData()
  }, [firstName, lastName, email, password, confirmPassword, dateOfBirth])

  const handleGoogleLogin = async token => {
    try {
      const signedUpForJob = !!redirectUrl && redirectUrl.startsWith('/jobs')
      const response = await signInWithGoogle(
        token,
        UserRole.STUDENT,
        referrer,
        !!signedUpForJob
      )
      const result = response.data
      if (result) {
        identifyUser(result._id, { email: result.email })
        segmentIdentifyCallback(result._id, {
          name: result.name,
          email: result.email,
          userRole: result.role
        })
        segmentTrackCallback(SegmentEventType.SIGNUP_WITH_GOOGLE_BUTTON, {
          dateTime: new Date(),
          fromUrl: router.asPath,
          redirectUrl: redirectUrl,
          role: result.role
        })
      }
      if (!result.error) {
        login(result.token)
        await redirectUser(result)
      } else {
        notify({ message: result.error, type: 'error' })
      }
    } catch (error) {
      setErrors({
        social: <Parser content={error?.response?.data?.message}></Parser>
      })
    }
  }

  const handleCaptcha = async () => {
    if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available')
      return ''
    }
    return await executeRecaptcha()
  }

  const handleSignup = async () => {
    setLoading(true)
    try {
      const redirectUrl = loginRedirect('get')
      const signedUpForJob = !!redirectUrl && redirectUrl.startsWith('/jobs')
      const payload: ApprenticeSignupDto = {
        firstName,
        lastName,
        email,
        password,
        dateOfBirth,
        signedUpForJob,
        referrer
      }
      if (invite) {
        set(payload, 'invite', invite)
      }
      if (isGdprAccepted) {
        set(payload, 'gdprAcceptedAt', isGdprAccepted)
      }
      const gReCaptchaToken = await handleCaptcha()
      const response = await registerApprenticeUser(payload, gReCaptchaToken)
      const { data } = response

      if (data) {
        notify({
          message:
            'Verification link has been sent to you email address. Please verify',
          type: 'success'
        })
        identifyUser(data._id, { email: data.email })
        segmentIdentifyCallback(data._id, {
          name: data.firstName + ' ' + data.lastName,
          email: data.email,
          userRole: data.role
        })
        router.push(
          `/verify-apprentice?operation=${encodeURIComponent(email)}${
            apprenticeInstantJobId
              ? `&apprenticeInstantJobId=${apprenticeInstantJobId}`
              : ''
          }`
        )
        segmentTrackCallback(SegmentEventType.VERIFY_EMAIL_BUTTON, {
          dateTime: new Date(),
          fromUrl: router.asPath,
          redirectUrl: redirectUrl,
          email: email,
          role: data.role,
          referrer
        })
      } else {
        notify({ message: data.message, type: 'error' })
      }
    } catch (error) {
      if (error.response?.data?.message)
        notify({ message: error.response.data.message, type: 'error' })
      else notify({ message: error?.message, type: 'error' })
    }
    setLoading(false)
  }

  const validateData = () => {
    const errorMessages: Dictionary<JSX.Element | string> = {}

    if (!firstName) {
      errorMessages['firstName'] = 'Please provide your First name'
    }

    if (!lastName) {
      errorMessages['lastName'] = 'Please provide your Last name'
    }

    if (!email) {
      errorMessages['email'] = 'Please provide your Email'
    }

    if (!password) {
      errorMessages['password'] = 'Please provide a Password'
    }

    if (!(password === confirmPassword)) {
      errorMessages['confirmPassword'] = "Passwords don't match"
    }

    if (!dateOfBirth) {
      errorMessages['dateOfBirth'] = 'Please provide your Date of Birth'
    } else {
      const years = moment().diff(dateOfBirth, 'years', false)
      if (years < 13) {
        errorMessages['dateOfBirth'] = (
          <div>
            Sorry, but we cannot complete your sign up, your age does not comply
            with our age-restriction policy, please check our{' '}
            <a
              className="underline"
              href={`${process.env.APP_URL}/privacy-policy`}
              target="_blank"
              rel="noreferrer">
              privacy policy
            </a>
          </div>
        )
      }
    }

    if (Object.keys(errorMessages).length > 0) {
      setErrors(errorMessages)
      setDisableSubmit(true)
      return false
    } else {
      setErrors({})
      setDisableSubmit(false)
      return true
    }
  }

  return (
    <div className="flex min-h-screen items-center justify-around bg-white py-12">
      <div className="flex flex-col md:w-1/2 lg:w-1/3 xl:w-1/4 px-5 md:px-0">
        <form
          // className="flex flex-col max-w-sm"
          onSubmit={e => {
            try {
              e.preventDefault()
              setHasSubmitted(true)
              if (validateData()) {
                if (!isTermsAgreed) {
                  notify({
                    message: 'Please accept terms and conditions',
                    type: 'error'
                  })
                } else {
                  handleSignup()
                }
              }
            } catch (error) {
              notify({
                message: error || 'Unable to complete signup',
                type: 'error'
              })
            }
          }}>
          <div className="mb-4">
            <div className="w-full h-16 py-2">
              <Photo url={qureosRevisedLogo} title="Qureos" className="w-40" />
            </div>
          </div>
          <AuthTabs type="signup" modeIndex="2" redirectTo="" />
          <div className="flex flex-col items-start mb-6">
            <h2 className="text-3xl text-gray-900 font-extrabold  mb-2">
              Sign up as candidate
            </h2>
            <p className="text-gray-600">
              Already have a candidate account?{' '}
              <span
                onClick={() => onAuthTypeChange(AuthType.LOGIN)}
                className="cursor-pointer text-qureosPrimary">
                Log in
              </span>
            </p>
          </div>

          <CorporateSignupStep1
            isApprentice
            data={{
              firstName,
              lastName,
              email,
              password,
              confirmPassword,
              dateOfBirth
            }}
            maxPasswordLength={48}
            showCount={true}
            errors={errors}
            onChange={userData => {
              setFirstName(userData.firstName)
              setLastName(userData.lastName)
              setEmail(userData.email)

              if (userData.password) {
                const passwordProgress = validatePassword(userData.password)
                setPasswordStrength(passwordProgress)
              }
              setPassword(userData.password)
              setConfirmPassword(userData.confirmPassword)
              setDateOfBirth(userData.dateOfBirth)
            }}
          />

          {password && (
            <div className="mt-6">
              <PasswordStrength data={passwordStrength} />
            </div>
          )}
          <div className="flex flex-col my-5 gap-3 ">
            <div className="flex items-center w-full">
              <Input
                type="checkbox"
                className="h-4 w-4 checked:bg-qureosPrimary ring-qureosPrimary"
                checked={isTermsAgreed}
                onChange={e => setIsTermsAgreed(e.target.checked)}
              />
              <div className="ml-2">
                I agree to{' '}
                <Link href="/terms-conditions">
                  <a className="font-semibold underline">
                    terms &amp; conditions
                  </a>
                </Link>
              </div>
            </div>
            <div className="flex w-full">
              <Input
                type="checkbox"
                className="h-4 w-4 mt-1 checked:bg-qureosPrimary ring-qureosPrimary"
                checked={isGdprAccepted}
                onChange={e => setIsGdprAccepted(e.target.checked)}
              />
              <div className="ml-2">
                I accept the{' '}
                <Link href="/privacy-policy">
                  <a className="font-semibold underline">
                    use of my personal data
                  </a>
                </Link>{' '}
                to receive the latest Qureos offers and updates.
              </div>
            </div>
          </div>

          <Button
            theme="normal"
            className="text-sm font-medium my-3"
            rounded="normal"
            width="full"
            loading={loading}
            disabled={disableSubmit || !!loading}>
            Verify Email
          </Button>

          <SocialLoginButtons
            title="Sign up with"
            googleCallback={(dto: GoogleDto) => {
              handleGoogleLogin(dto.code)
            }}
            errors={errors}
          />
        </form>
      </div>
      <div className="hidden lg:flex lg:visible">
        <QureosCarousel
          data={apprenticeCarouselDataBlue}
          showPagination={true}
          autoplay={true}
        />
      </div>
    </div>
  )
}

export default ApprenticeSignup
